<!-- 2018-03-02 23:59:00 -->
<!-- "Y-m-d H:i:s" -->

<template id="countdown-template">
    <div class="countdown">
        <div class="block">
            <p class="digit">{{ minutes | twoDigits }} :</p>
        </div>
        <div class="block">
            <p class="digit">{{ seconds | twoDigits }}</p>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        window.setInterval(() => {
            this.now = Math.trunc(new Date().getTime() / 1000);

            if(Number(this.minutes) <= 0 && Number(this.seconds) <= 0){
            this.$emit('onEnd');
            }
        }, 1000);
    },

    props: {
        date: {
            type: String
        }
    },

    data() {
        return {
            now: Math.trunc(new Date().getTime() / 1000)
        }

    },

    computed: {
        dateInMilliseconds() {
            return Math.trunc(Date.parse(this.date) / 1000);
        },

        seconds() {
            return (this.dateInMilliseconds - this.now) % 60;
        },

        minutes() {
            return Math.trunc((this.dateInMilliseconds - this.now) / 60) % 60;
        },

        hours() {
            return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60) % 24;
        },

        days() {
            return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60 / 24);
        },
    }
};
</script>

<style lang="scss" scope>
.countdown {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.block {
  display: flex;
  flex-direction: column;
}

.digit {
  color: #45b171;
  font-size: 22px;
  margin: 5px;
}
</style>
