<template>
    <div class="verify">
        <div class="left" :style="{'background-image': `url(${require(`@/assets/img/${language}.png`)})`}"></div>
        <div class="right">
            <img class="close" :src="require('@/assets/img/close.svg')" alt="close" @click="$router.push('/')">

            <div class="verify-container">
                <div class="verify-wrapper">
                    <img :src="require('@/assets/img/logo.png')" alt="logo" class="logo">
                    <p class="title">{{ $t('verification') }}</p>
                    <p class="desc">{{ $t('thank_you_sign_up') }}</p>
                    <p class="desc">{{ $t('verification_desc') }}</p>

                    <div class="timer-wrapper">
                        <Countdown
                            v-if="!isTimerEnd"
                            :date="dateToday"
                            @onEnd="endTimer()"
                        ></Countdown>
                        <a @click="resendCode" class="resend" :class="{'disabled' : !isTimerEnd || loading }">{{ $t('resend_code') }}</a>
                        <!-- <a @click="resendCode" class="resend" :class="{'disabled' : loading }">{{ $t('resend_code') }}</a> -->
                    </div>

                    <div class="input-container">
                        <input v-model="code.first" type="text" maxlength="1" ref="first" @keyup="goNext($event)">
                        <input v-model="code.second" type="text" maxlength="1" @keyup="goNext($event)">
                        <input v-model="code.third" type="text" maxlength="1" @keyup="goNext($event)">
                        <input v-model="code.fourth" type="text" maxlength="1">
                    </div>

                    <p class="_error" v-if="invalid">{{ $t('invalid_code') }}</p>

                    <button class="submit" @click="verify" :disabled="loading">{{ $t('verify') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
// import Cookies from 'js-cookie'
import Countdown from "@/components/utils/Timer"

export default {
    data () {
        return {
            isShow: false,
            isTimerEnd: false,
            dateToday: "",
            // loading: false,
            invalid: false,

            code: {
                first: '',
                second: '',
                third: '',
                fourth: '',
            }
        }
    },

    computed: {
        ...mapState({
            tempUser: state => state.tempUser,
            loading: state => state.loading,
            language: state => state.language
        })
    },

    components: {
        Countdown
    },

    mounted () {
        if (!this.tempUser) this.$router.push('/sign-in')

        this.startTime()
        this.$refs.first.focus()
    },

    methods: {
        async verify () {
            // this.loading = true
            let { first, second, third, fourth } = this.code
            let submittedCode = first+second+third+fourth

            let form = new FormData
            form.append('code', submittedCode)
            let res = await this.$store.dispatch('verify', form)

            if (!res) this.invalid = true
            
            // if (parseInt(submittedCode) === this.tempUser.verification_code) {
            //     this.$store.commit('SET_USER', this.tempUser)
            //     Cookies.set('token', this.tempUser.api_token, { expires: 36500 })
            //     this.$store.commit('SET_TOKEN', this.tempUser.api_token)
            //     this.$store.commit('SET_TEMP_USER', null)
            //     this.$store.commit('SET_TEMP_TOKEN', null)
            //     location.reload()
            //     // this.$router.push('/dashboard')
            // } else {
            //     this.invalid = true
            // }
            // this.loading = false
        },

        async resendCode () {
            await this.$store.dispatch('resendCode')
            this.startTime()
        },

        endTimer() {
            this.isTimerEnd = true
        },

        startTime () {
            this.isTimerEnd = false
            let today = new Date()
            today.setMinutes(today.getMinutes() + 5)

            const formatMap = {
                hh: today.getHours(),
                ii: today.getMinutes(),
                ss: today.getSeconds(),
                mm: today.getMonth() + 1,
                dd: today.getDate(),
                yy: today
                .getFullYear()
                .toString()
                .slice(-2),
                yyyy: today.getFullYear()
            }

            const value = `${formatMap.yyyy}-${formatMap.mm}-${formatMap.dd} ${formatMap.hh}:${formatMap.ii}:${formatMap.ss}`
            this.dateToday = value
        },

        goNext (e) {
            e.target.nextElementSibling.value = ''
            e.target.nextElementSibling.focus()
        }
    }
}
</script>

<style lang="scss" scoped>
.verify {
    display: flex;

    .left {
        @include desktop {
            flex: 1;
            height: 100vh;
            // background: #47435c url('~@/assets/img/sign_placeholder.jpg') no-repeat center top;
            background-repeat: no-repeat;
            background-size: cover;
            // opacity: 0.8;
            border-radius: 0 4px 4px 0;
        }
    }

    .right {
        display: flex;
        flex-direction: column;
        flex: 1;
        position: relative;

        .close {
            cursor: pointer;
            position: absolute;
            top: 30px;
            right: 30px;

            width: 15px;
            height: 15px;

            @include desktop {
                width: unset;
                height: unset;
            }
        }

        .verify-container {
            padding: 0 20px;
            // margin: auto;
            margin: 70px auto 20px;

            @include desktop {
                margin: auto;
                width: 425px;
            }

            .verify-wrapper {
                text-align: center;

                .logo {
                    display: block;
                    width: 150px;
                    height: 100px;
                    margin: auto;

                    @include desktop {
                        display: none;
                    }
                }

                .title {
                    font-family: 'Crimson Pro', sans-serif;
                    font-weight: 600;
                    font-size: 24px;
                    color: #00AEEF;
                    margin-bottom: 20px;
                }

                .desc {
                    color: #B8B8B8;
                    font-size: 14px;
                    margin-bottom: 12px;
                }

                .timer-wrapper {
                    margin: 35px auto 50px;
                    
                    .resend {
                        text-decoration: underline;
                        color: #00AEEF;
                        font-size: 14px;
                        cursor: pointer;

                        &.disabled {
                            color: #B8B8B8;
                            text-decoration: none;
                            cursor: not-allowed;
                            pointer-events: none;
                        }
                    }
                }

                .input-container {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 55px;

                    input {
                        width: 35px;
                        border: 0;
                        border-bottom: 1px solid #B8B8B8;
                        margin-right: 16px;
                        text-align: center;
                        padding-bottom: 12px;
                        font-size: 20px;
                        font-weight: 600;
                    }
                }

                ._error {
                    margin-bottom: 30px;
                }

                .submit {
                    width: 100%;
                    background-color: #00AEEF;
                    color: #ffffff;
                    font-size: 16px;
                    font-weight: 600;

                    &:disabled {
                        opacity: 0.7;
                        pointer-events: none;
                    }
                }
            }
        }
    }
}
</style>